.sidebar .backdrop {
  background: #00a49d;
  filter: blur(400px);
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.sidenav {
  height: 100%;
  width: 320px;
  transition: left 0.4s ease;
  position: fixed;
  z-index: 1;
  background: #181826;
  padding: 0px;
  display: flex;
  flex-direction: column;
  left: -400px;
}

.sidenav.show {
  left: 0px;
}

.sidenav .img_detail {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.sidenav .img_detail img {
  width: 50%;
}

.sidenav .closebtn {
  font-size: 36px;
  border: solid 2px #00a49d;
  border-radius: 8px;
  color: #00a49d;
  width: 40px;
  height: 45px;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  left: 102%;
}

.sidenav .sidebarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px 16px 32px;
  margin-top: 24px;
}

.sidebarHeader img {
  width: 200px;
}

.sidenav hr {
  width: 272px;
  margin-top: 20px;
  opacity: 0.6;
  margin: 8px;
  align-self: center;
  background: linear-gradient(to right, #d3d3d3, #d3d3d300);
  height: 1px;
  border: 0;
}

.sidebar .volume_icon {
  margin: 0 0 0 16px;
  display: flex;
  align-items: center;
}

.sidebarContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 8px 0 24px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.sidebar .images_footer {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.sidebar .images_footer img {
  width: 70%;
  margin: 4px;
}

.logo_fixa {
  position: fixed;
  margin-top: 16px;
  width: 232px;
  top: 0;
  left: 0;
}

.logo_fixa img {
  width: 162px;
  -webkit-filter: drop-shadow(1px 1px 1px #333);
  filter: drop-shadow(1px 1px 1px #333);
}

.logo_fixa .backgroundBlur {
  background: #00a49d 70%;
  filter: blur(120px);
  width: 380px;
  height: 112px;
  position: absolute;
  top: -24px;
  left: -90px;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .sidenav {
    width: 280px;
  }

  .sidenav .closebtn {
    font-size: 24px;
    width: 30px;
    height: 30px;
  }

  .sidenav hr {
    align-self: center;
  }

  .sidebar .images_footer {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0px;
  }

  .sidebar .images_footer img {
    width: 35%;
    margin: 4px;
  }

  .sidebar .volume_icon {
    margin: 8px;
  }
}
