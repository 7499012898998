.videoModal{
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.videoModalBG{
    background-color: rgba(0, 164, 157, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.videoModalContainer{
    width: 100%;
}


.videoModalHeader{
    background-color: #00A49D;
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;   
    height: 64px;
    max-height: 64px;
    width: 100%;   
    
}

.videoModalHeader button{
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0 24px;

}

.videoModalHeader p{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    margin: 0 24px;
}

.ifameVideo{
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}

.ifameVideo iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .ifameVideo{
        width: 100%;
        height: 100%;
    }

    .videoModalHeader{
        justify-content: flex-end;
    }
    .videoModalHeader p{
        display: none;
    }
    
}