.titleModal{
  width: 100%;
}

.titleModal h2{
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #00A49D;
  border-bottom: #00A49D solid 3px;
  padding-bottom:20px;
  text-align: left;
  
}

@media screen and (max-width: 768px) {
  .titleModal h2{
      font-size: 16px;
  }
}