.galeriaModal{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
    padding: 16px 0;
}

.imgGaleria{
    width: calc(50% - 16px);
    position: relative;
}

.imgGaleria img{
    width: 100%;
}

.hover{
    position: absolute;
    background: transparent;
    width: 100%;
    height: 98%;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all ease 0.5s;
}

.hover:hover{
    background: RGBA(0,164,157,0.59);
    cursor: pointer;
    opacity: 1;
}

.hover svg{
    transition: all ease 0.5s;
}

.hover svg:hover{
    transform: scale(1.2);
}

