.contactModal{  
  width: 100%;
}

.contactModal .line{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.contactModal p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #787878;
  width: 100%;
  text-align: left;
  margin: 0px;
}

.contactModal a {
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .contactModal p {
    font-size: 12px;
}
}
