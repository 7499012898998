.modalCentro {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    width: 100vw;
    height: 100vh;
  }
  
  .modalCentro .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    background: #00a49d;
    filter: blur(500px);
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }
  
  .modalCentroShow {
    display: flex;
  }
  
  .modalCentroContainer {
    width: 744px;
    z-index: 3;
  }
  
  .modalCentroHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #edf4f4;
    padding: 0 16px;
    height: 64px;
  }
  
  .modalCentroHeader button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .modalCentroContent {
    background-color: white;
    padding: 32px 96px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .modalCentroContent iframe{
    width: 100%;
  }
  
  .modalCentroFooter {
    width: 100%;
  }
  
  .modalCentroFooter img {
    width: 100%;
  }
  
  @media screen and (max-width: 768px) {
    .modalCentroContainer {
      width: 315px;
    }
  
    .modalCentroContent {
      width: 299px;
      height: 400px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .modalCentroFooter img {
      width: 315px;
    }
  }
  