.pdfModal{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1001;
}

.pdfModal .backdrop{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pdfModal .content{
    width: 100%;
    height: 100%;    
    overflow: hidden;  
    display: flex;
    flex-direction: column;
    justify-content: center;    
    align-items: center;  
}

.pdfHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #00A49D;  
  width: 100%;
  height: 48px;
}

.pdfHeader p {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-left: 16px;
  color: #ffffff;
}

.pdfHeader button{
    margin-right: 16px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.pdfContent{
    width: 100%;
    max-width: 1280px;
    height: calc(100vh - 96px);
}

.pdfContent .loader{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rpv-core__inner-pages::-webkit-scrollbar {
    width: 7px;    
    
  }
  .rpv-core__inner-pages::-webkit-scrollbar-track {
    box-shadow: 0 0 5px grey;
    background: #adadad;
}
   
.rpv-core__inner-pages::-webkit-scrollbar-thumb {
    background: #00A49D; 
    border-radius: 5px;
    border: 0;
}


.pdfFooter{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 48px;
}

.pdfFooterContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    height: 48px;
    gap: 24px;
}

.pdfFooterContainer svg {
    cursor: pointer;
}

.paginas{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 24px;
    
}

.paginas p{
    font-weight: 700;
    font-size: 16px;
    color: #00A49D;
}

.icons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 24px;    
}



@media screen and (max-width: 768px) {
    .pdfHeader {       
        justify-content: flex-end;        
      }
      
      .pdfHeader p {
        display: none;
      }
}
