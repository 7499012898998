.open-video-modal{
    width: 50%;
}

.openVideo{
    margin: 16px 0 0 0 ;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    cursor: pointer;
    color: #792dae;
}

.galleryContainer .openVideo{
    width: calc(100% - 16px) !important;
    height: 0 !important;
    padding-bottom: 56.25% !important;
}

.openVideo img{
    width: 100%;
    height: 56.25%;
    cursor: pointer;
}

.openVideo svg{
    cursor: pointer;
    position: absolute;
    top: calc(50% - 27px);
    left: calc(50% - 27px);
}

.openVideo .imageFilter{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
}

.openVideoTitle{
    width: calc(100% - 16px) !important;
}

.openVideoTitle h3{
    margin: 0 0 16px 0;
    color: #792dae;
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    .open-video-modal{
        width: 100% !important;
    }
}