.paragraphModal{  
  width: 100%;
}

.paragraphModal p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #787878;
  width: 100%;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .paragraphModal p {
    font-size: 12px;
}
}
