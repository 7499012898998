html {
  height:100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%;
  background: black;
  color: white;
  overflow:hidden; /* disable scrollbars */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* remove highlight on tab for iOS/Android */
}

/* fix for scroll bars on webkit & >=Mac OS X Lion */
/* ::-webkit-scrollbar {
  background-color: rgba(0,0,0,0.5);
  width: 0.75em;
  height: 0.75em;
}
::-webkit-scrollbar-thumb {
  background-color:  rgba(255,255,255,0.5);
} */

::-webkit-scrollbar {
  width: 12px;
  height: 0.75em;
  background-color: rgba(0,0,0,0.5);
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background-color: #00A49D;
  border-radius: 8px;
  border: 3px solid white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
