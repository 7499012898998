.sidebarParagraphs{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
  
.sidebarParagraphs p {
    padding: 2px 2px 2px 2px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    margin: 0 0 0 16px;
}

.sidebarParagraphs .details{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.liveNotification{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 59px;
    height: 20px;
    background-color: #F4455A;    
    color: #ffffff;
    border-radius: 10px;
    margin-top: 2px;
}

.liveNotification > p {
    padding: 0;
    font-weight: bold;
    font-size: 10px;
    color: #ffffff;
    margin: 0;
}

.sidebarArrow{
    width: 40px;
    position: relative;    
}

.sidebarArrow svg {
    position: absolute;
    top: -6px;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s linear, transform 0.3s ease-out;    
}

.sidebarParagraphs:hover svg {
    opacity: 1;
    transform: translateX(-24px);
}

@media screen and (max-width: 767px) {
    .sidebarParagraphs p {
       margin: 0;
        font-size: 12px;
    }

    .liveNotification > p {        
        font-size: 10px;
        
    }
}