.linkexternoModal .content {
    width: 100%;
    height: 51px;
    background: #00A49D;
    color: white;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.linkexternoModal{
    padding: 16px 0;
    width: calc(50% - 32px);
}

.linkexternoModal a{
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;  
    text-decoration: none;  
}

@media screen and (max-width: 768px) {
    .linkexternoModal {
        width: 180px;
        height: 30px;
        margin-bottom: 10px;
    }
      
    .linkexternoModal a{
        font-size: 16px;
    }

    .linkexternoModal .content {
        padding: 2px 10px 4px;
    }
}