.login {
  display: flex;
  z-index: 10;
}

.image_background img {
  width: 100%;
}

.image_background_detail {
  display: flex;
  flex-direction: column;
}

.image_background_detail img {
  width: 100%;
}

.login_card {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 56px;
  gap: 32px;
  width: 25%;
  height: 52%;
  background: rgba(24, 24, 38, 0.4);
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: #434360;
  box-shadow: 0px 16px 32px -8px rgba(4, 4, 7, 0.3);
  backdrop-filter: blur(24px);
  border-radius: 8px;
  top: 2%;
  left: 10%;
  z-index: 10;
}

.login_card .title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #edf4f4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login_card .subtitle {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #646464;
  margin-top: 8px;
}

.login_card .input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.login_card .input input {
  width: 95%;
  margin-top: 8px;
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-color: #00a49d;
  border-radius: 8px;
  outline: 0;
  padding: 8px;
  color: #fff;
}

.login_card .input input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #00a49d;
}

.login_card .input .button_input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px;
  width: 100%;
  height: 10px;
  background: #00a49d;
  margin-top: 16px;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: #edf4f4;
  cursor: pointer;
  border: 0;
  outline: 0;
}

.cadastrar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.cadastrar .title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #edf4f4;
}

.cadastrar .button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px;
  width: 90%;
  height: 10px;
  background: transparent;
  margin-top: 16px;
  border-radius: 8px;
  border: 1px solid #00a49d;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #00a49d;
  cursor: pointer;
}

.footer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 56px 56px 56px;
  gap: 16px;
  width: 25%;
  height: 12%;
  background: rgba(24, 24, 38, 0.4);
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: #434360;
  box-shadow: 0px 16px 32px -8px rgba(4, 4, 7, 0.3);
  backdrop-filter: blur(24px);
  border-radius: 8px 8px 0px 0px;
  bottom: 2%;
  left: 10%;
  z-index: 10;
}

.footer .text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  text-align: left;
}

.footer .images {
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  width: 100%;
  gap: 16px;
}

.footer .images img {
  width: 100%;
}

.footer .logos {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  width: 40%;
}

.login_card .input .cardboard{
  display: flex;
  justify-content: center;
  width: 100%;
  color: #00a49d;
  font-weight: 700;
  margin-top: 8px;
  cursor: pointer;
}

.card_logo_mobile{
  display: none;
}

.detail_mobile{
  display: none;
}

@media screen and (max-width: 768px) {
  .image_background img {
    display: none;
  }

  .login_card {
    width: 50%;
    top: 15%;
    left: 12%;
    height: 35%;
    align-items: center;
    background: #181826;
  }

  .login_card img {
    width: 80%;
    margin: 0;
  }

  .login_card .title {
    font-size: 20px;
    margin: 0;
  }

  .login_card .subtitle {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #a7a7a7;
    margin-top: 8px;
  }

  .login_card .input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: -16px;
  }

  .login_card .input input {
    margin: 0;
    padding: 4px;
  }

  .login_card .input input::placeholder {
    font-size: 12px;
  }

  .login_card .input .button_input {
    padding: 24px;
    height: 1%;
    margin-top: 8px;
    font-size: 12px;
  }

  .cadastrar {
    margin-top: -32px;
  }

  .cadastrar .button {
    width: 80%;
    height: 1%;
    font-size: 10px;
  }

  .cadastrar .title {
    font-size: 14px;
  }

  .footer {
    padding: 16px;
    gap: 8px;
    width: 70%;
    height: 15%;
    bottom: 4%;
    left: 12%;
  }

  .footer .text {
    font-size: 12px;
    text-align: start;
  }

  .login_card .input .cardboard{
    font-size: 11px;
  }

  .login_card .input .cardboard div{
    margin-top: 4px;
  }

  .logo_metazonia{
    display: none;
  }

  .card_logo_mobile{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    top: -35%;
    left: -20%;
    background: #181826;
    width: 140%;
    height: 30%;
  }

  .card_logo_mobile .logo_metazonia_mobile img{
    width: 50%;
  }

  .detail_mobile{
    display: block;
    position: absolute;
    bottom: -25%;
    left: -20%;
  }

  .detail_mobile img{
    width: 115%;
  }

  .footer .logos{
    margin-top: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1070px) {
  .login_card {
    width: 45%;
  }

  .footer {
    width: 45%;
  }

  .image_background img {
    width: 164%;
  }

  .image_background_detail {
    display: flex;
    flex-direction: column;
  }

  .image_background_detail img {
    width: 170%;
  }
}
