.cadastro_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 16px;
  position: fixed;
  top: 5%;
  left: 15%;
  width: 350px;
  background: #00a49d;
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: #434360;
  box-shadow: 0px 16px 32px -8px rgba(4, 4, 7, 0.3);
  backdrop-filter: blur(24px);
  border-radius: 8px;
  z-index: 10;
}

.cadastro_div .title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #edf4f4;
  text-align: start;
  margin-left: 16px;
  margin-bottom: 16px;
}

.cadastro_div .input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.cadastro_div .input label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #edf4f4;
  margin-left: 16px;
}

.cadastro_div .input input {
  width: 95%;
  margin-top: 8px;
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-color: #edf4f4;
  border-radius: 8px;
  outline: 0;
  padding: 8px;
  color: #FFF;
}

.cadastro_div .input input::placeholder{
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #edf4f4;
}

.cadastro_div .input select{
  width: 100%;
  margin-top: 8px;
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-color: #edf4f4;
  border-radius: 8px;
  outline: 0;
  padding: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #edf4f4;
  cursor: pointer;
}

.cadastro_div .input select option{
  background-color: #181826;
  cursor: pointer;
}

.cadastro_div .checkbox label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  color: #EDF4F4;
  text-align: left;
}

.cadastro_div .button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  width: 100%;
  height: 48px;
  background: #79ba6d;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: #edf4f4;
  cursor: pointer;
  outline: 0;
  border: 0;
}

.errorMessage{
  margin: 0;
  padding: 0;  
}


@media screen and (max-width: 768px) {
    .cadastro_div{
        width: 60%;
        height: 80%;
    }

    .cadastro_div .title {
      font-size: 16px;
      margin: 0;
    }

    .cadastro_div .input input {
      width: 90%;
      margin-top: 0px;
      padding: 4px 0 8px 8px;
    }
    
    .cadastro_div .input input::placeholder{
      font-size: 12px;
    }

    .cadastro_div .checkbox label {
      font-size: 12px;
    }

    .cadastro_div .button {
      padding: 16px 24px;
      width: 80%;
      height: 10px;
      font-size: 16px;
    }

    .cadastro_div .input select{
      width: 80%;
    }
}