.modalWelcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100%;
}

.modalWelcome .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background: #00a49d;
  filter: blur(500px);
  width: 100vw;
  height: 100%;
  z-index: -1;
}

.modalWelcomeShow {
  display: none;
}

.modalWelcomeContainer {
  width: 744px;
  z-index: 3;
}

.modalWelcomeHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #edf4f4;
  padding: 0 16px;
  height: 32px;
}

.modalWelcomeHeader button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 72px;
}

.modalWelcomeContent {
  background-color: white;
  padding: 32px 96px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.modalWelcomeContent h1 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #00a49d;
}

.modalWelcomeContent .rowContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;
  text-align: left;
}

.modalWelcomeContent .rowContent p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #737373;
  padding-left: 32px;
  margin: 0px;
}

.svgContainer {
  width: 66px;
  min-width: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hr-element {
  color: #c4c4c4;
  background-color: #c4c4c4;
  width: 100%;
  height: 1px;
  border: none;
}

.modalWelcomeContent .rowContent3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rowContent3 .paragraph {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #737373;
}

.rowContent3 .guiaBtn {
  width: 208px;
  height: 51px;
  background: #3b4aff;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.rowContent3 .guiaBtn p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
}

.modalWelcomeFooter {
  width: 100%;
}

.modalWelcomeFooter img {
  width: 100%;
}

.modalWelcomeContent .btn_acessar {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 24px 0 0 0;
}

.modalWelcomeContent .btn_acessar button {
  background-color: #00a49d;
  border: none;
  width: 140px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #edf4f4;
}

@media screen and (max-width: 768px) {
  .modalWelcomeContainer {
    width: 315px;
  }

  .modalWelcomeContent {
    width: 299px;
    height: 400px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modalWelcomeContent h1 {
    font-size: 32px;
    padding-left: 8px;
  }

  .modalWelcomeContent .rowContent {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .modalWelcomeContent .rowContent p {
    font-size: 16px;
    padding: 0;
  }

  .paragraphResponsive {
    margin-top: 16px;
  }

  .modalWelcomeFooter img {
    width: 315px;
  }

  .modalWelcomeHeader button {
    margin-left: 16px;
  }
}
