.Openpdf {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 50%;
  gap: 32px;
  padding: 16px 0;
}

.galleryContainer .Openpdf{
  width: calc(50% - 16px) !important;
}

.online {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 32px);
  height: 30px;
  padding: 10px;
  border: #f4455a solid 2.5px;
  border-radius: 10px;
  cursor: pointer;
}

.online p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #f4455a;
  margin-left: 24px;
}

@media screen and (max-width: 768px) {
  .Openpdf{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .online{
    margin-top: 16px;
  }
}
