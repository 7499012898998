.divOpenBtn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    position: fixed;
    bottom: 0;
    left: 0%;
    width: 180px;
    height: 56px;
    background: transparent;    
    transition: all ease 1s;
}

.divOpenBtn:hover{
    transform: scale(1.1);
}

.divOpenBtn .backgroundBlur {
    background: rgba(24, 24, 38, 0.7);
    filter: blur(32px);
    width: 328px;
    height: 200px;
    position: absolute;
    top: -50px;
    left: -90px;
    z-index: -1;
}

.divOpenBtn .divBtn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 50%;
    background: transparent;
    gap: 24px;
}


.openBtn {
    width: 102px;
    height: 32px;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
    color: #00A49D;
    border: 2px solid #00A49D;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.openBtn p{
    margin: 0;
}

@media screen and (max-width: 767px) {

    .openBtn {
        width: 100px;
        height: 30px;
        font-size: 16px;
        background-color: transparent;
        color: #00A49D;
        border: 2px solid #00A49D;
        border-radius: 8px;
        cursor: pointer;
    }
}
