.openModal {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0%;
  right: 0%;
  width: 15vw;
  height: 10vh;
}

.openModalBtn {
  width: 150px;
  height: 40px;
  font-size: 20px;
  background-color: red;
  border-radius: 8px;
  cursor: pointer;
}

.modal {
  height: 100%;
  width: 800px;
  transition: right 0.5s ease;
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: white;
  display: flex;
  flex-direction: column;
  right: -800px;
}

.modal.showModal {
  right: 0px;
}

.modalHeader {
  background-color: #edf4f4;
  height: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}

.closeModalBtn {
  border: none;
  cursor: pointer;
  background-color: #edf4f4;
  margin-left: 28px;
}

.modalRodape {
  margin: 0 0 -4px 0;
}

.modalRodape img {
  width: 100%;
}

.modalRodape .imgOpacity {
  margin-top: -20px;
  height: 20px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 1) 90%
  );
}

.modalContent {
  height: fit-content;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2%;
  padding: 0px 32px;
  max-height: 100%;
  width: 100%;
}

.modalContent::-webkit-scrollbar {
  width: 12px;
}

.modalContent::-webkit-scrollbar-track {
  background: white;
}

.modalContent::-webkit-scrollbar-thumb {
  background-color: #00A49D;
  border-radius: 8px;
  border: 3px solid white;
}

.backGroundModal {
  background-color: transparent;
  transition: background-color 0.5s ease;
}

.backGroundModalShow {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  z-index: 999;
}

.VideoModal {
  position: fixed;
  top: 20%;
  left: 20%;
  z-index: 2;
}

.PDfModal {
  position: fixed;
  top: 12%;
  left: 60%;
  z-index: 2;
}

.clickBack {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: transparent;
  transition: opacity 0.3s ease;
  z-index: 998;
  opacity: 0;
}

.clickBackShow {
  background: #00a49d;
  filter: blur(800px);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 1;
}

.galleryContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.modalRodape {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.modalRodape img {
  height: 60%;
}

.openModal{
  position: absolute;
  right: 5%;
  bottom: 3%;
  width: 120px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  border-color: #00a49d;
  color: #00a49d;
  background-color: #edf4f4;
  font-size: 16px;
  cursor: pointer;
  transition: all ease 1s;
}

.openModal:hover{
  transform: scale(1.2);
  background-color: #00a49d;
  color: #edf4f4;
  border-color: #edf4f4;
}

@media screen and (max-width: 768px) {
  .modal {
    height: 100%;
    width: 80%;
    right: -350px;
  }

  .modalContent {
    padding: 0 30px;
    margin-bottom: 30px;
  }

  .modalContent h1 {
    font-size: 15px;
  }

  .modalContent p {
    font-size: 10px;
  }

  .VideoModal {
    position: fixed;
    top: 20%;
    left: 10%;
    z-index: 2;
  }

  .PDfModal {
    position: fixed;
    top: 12%;
    left: 30%;
    z-index: 2;
  }

  .closeModalBtn{
    margin-left: 28px;
  }
}
